import { values } from 'lodash';

// The following colors will be used if you pick "Automatic" color
export const BaseColors = {
  Blue: '#356AFF',
  Red: '#E92828',
  Green: '#3BD973',
  Purple: '#604FE9',
  Cyan: '#50F5ED',
  Orange: '#FB8D3D',
  'Light Blue': '#799CFF',
  Lilac: '#B554FF',
  'Light Green': '#8CFFB4',
  Brown: '#A55F2A',
  Black: '#000000',
  Gray: '#494949',
  Pink: '#FF7DE3',
  'Dark Blue': '#002FB4',
};

// Additional colors for the user to choose from
export const AdditionalColors = {
  'Indian Red': '#981717',
  'Green 2': '#17BF51',
  'Green 3': '#049235',
  DarkTurquoise: '#00B6EB',
  'Dark Violet': '#A58AFF',
  'Pink 2': '#C63FA9',
};

export const ColorPaletteArray = values(BaseColors);

const ColorPalette = {
  ...BaseColors,
  ...AdditionalColors,
};

export default ColorPalette;
